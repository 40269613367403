<template>
  <b-row>
    <b-col cols="12">
      <h5>
        <strong>{{ $t('clubs.powers.powerOfAttorneyRegistration') }}</strong>
      </h5>
      <span class="i-text-request-info">
        {{ $t('corporate.create.textRequest') }}
      </span>
      <hr>
    </b-col>
    <b-col cols="12">
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        opacity="1"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row>
          <b-col
            v-if="!applyOverlay"
            md="12"
          >
            <validation-observer ref="pOARules">
              <b-form @submit.prevent="updatingDataPOA = true">
                <b-row>
                  <b-col md="6">
                    <b-form-group for="poa-incorporation-date">
                      {{ $t('clubs.powers.form.instrumentDate') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="instrumentDate"
                        rules="required"
                      >
                        <b-form-datepicker
                          id="poa-incorporation-date"
                          v-model="formPowerOfNotary.instrumentDate"
                          hide-header
                          :locale="$i18n.locale"
                          :placeholder="$t('clubs.powers.placeholder.selectInstrumentDate')"
                          :state="errors.length > 0 ? false:null"
                          :max="todayPoa"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="poa-instrument-number">
                      {{ $t('clubs.instrumentNumber.labels.instrumentNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="instrumentNumber"
                        rules="required|integer|min:1|max:20"
                      >
                        <b-form-input
                          id="poa-instrument-number"
                          v-model="formPowerOfNotary.instrumentNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.enterInstrument')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="poa-country-notary">
                      {{ $t('clubs.instrumentNumber.labels.countryOfNotary') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="countryOfNotary"
                        rules="required"
                      >
                        <v-select
                          id="poa-country-notary"
                          v-model="formPowerOfNotary.countryOfNotaryId"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :reduce="option => option.id"
                          :options="countries"
                          :placeholder="$t('generic.selectCountry')"
                          @input="fetchStates(formPowerOfNotary.countryOfNotaryId)"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="poa-state-notary">
                      {{ $t('clubs.instrumentNumber.labels.stateOfNotary') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="stateOfNotary"
                        rules="required"
                      >
                        <v-select
                          id="poa-state-notary"
                          v-model="formPowerOfNotary.stateOfNotaryId"
                          :state="errors.length > 0 ? false : null"
                          :reduce="option => option.id"
                          label="name"
                          :options="states"
                          :placeholder="$t('generic.selectState')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="poa-notary-name">
                      {{ $t('clubs.instrumentNumber.labels.notaryName') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="notaryName"
                        rules="required|max:255|nameRegex"
                      >
                        <b-form-input
                          id="poa-notary-name"
                          v-model="formPowerOfNotary.notaryName"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.selectNotaryName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="poa-notary-number">
                      {{ $t('clubs.instrumentNumber.labels.notaryNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="notaryNumber"
                        rules="required|integer|min:1|max:12"
                      >
                        <b-form-input
                          id="poa-notary-number"
                          v-model="formPowerOfNotary.notaryNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.selectNotaryNumber')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="actions.name == 'edit'"
                    md="6"
                  >
                    <upload-file
                      :title="$t('generic.document')"
                      folder="clubs"
                      :club-id="formPowerOfNotary.clubId"
                      :file-title="formPowerOfNotary.documentFile ? formPowerOfNotary.documentFile.title : null"
                      @uploaded-file="assignFileDinamic($event)"
                    />
                  </b-col>
                  <b-col
                    v-else
                    md="6"
                  >
                    <b-form-group>
                      {{ $t('generic.document') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('generic.document')"
                        rules="required"
                      >
                        <b-form-file
                          ref="file-x-input"
                          v-model="auxFile"
                          type="file"
                          accept="image/jpeg, image/png, image/jpg, application/pdf"
                          :browse-text="$t('buttons.explore')"
                          :placeholder="$t('generic.uploadDocument')"
                          :state="errors.length > 0 ? false:null"
                          no-drop
                          @change="fileValidation"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <label
                      label-for="poa-current"
                      class="i-font-size-date"
                    >
                      {{ $t('generic.markAsCurrent') }} <br>
                      <b-row>
                        <b-col md="4">
                          <b-form-checkbox
                            v-model="formPowerOfNotary.isCurrent"
                            checked="true"
                            name="check-button"
                            switch
                            inline
                          >
                            <span>{{ formPowerOfNotary.isCurrent ? $t('generic.yes') : $t('generic.not') }}</span>
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </label>
                  </b-col>
                  <b-col md="12">
                    <rich-text-edit-poa
                      :text-one="$t('clubs.powers.form.observations')"
                      :text-two="'observations'"
                      :agreements="formPowerOfNotary.observations"
                      :updating-data="updatingDataPOA"
                      :apply-edition-x="actions.name"
                      :read-only-text="false"
                      @assign-text-edit="assignTextEdit"
                    />
                  </b-col>
                  <b-col md="12">
                    <hr>
                    <b-button
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      :style="colorPrimaryBtn"
                      type="submit"
                    >
                      {{ $t( 'buttons.save') }}
                    </b-button>
                    <b-button
                      class="float-right"
                      variant="secondary"
                      pill
                      @click="$emit('change-type-view')"
                    >
                      {{ $t('buttons.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            v-else
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer, min, max, nameRegex } from '@validations'
import { BRow, BCol, BFormGroup, BForm, BFormInput, BFormDatepicker, BButton } from 'bootstrap-vue'
import { destroyMediaFile } from '@/services/mediaFileService'
import countryService from '@/services/countryService'
import powerOAService from '@/services/powerOfAttorneyService'
import RichTextEditPoa from '@/views/partials/editors/RichTextEdit.vue'
import UploadFile from '@/views/member/components/UploadFile.vue'
import vSelect from 'vue-select'

export default {
  components: {
    UploadFile,
    RichTextEditPoa,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormDatepicker,
    vSelect,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      auxFile: [],
      statesList: [],
      countriesList: [],
      applyDeleteFile: false,
      previousFileHash: null,
      formPowerOfNotary: {
        id: null,
        clubId: null,
        instrumentDate: null,
        instrumentNumber: null,
        countryOfNotaryId: '',
        stateOfNotaryId: '',
        notaryName: null,
        notaryNumber: null,
        documentFile: null,
        documentFileId: null,
        observations: null,
        status: true,
        isCurrent: true,
      },
      todayPoa: new Date(),
      applyOverlay: false,
      updatingDataPOA: false,
      required, // validation
      integer, // validation
      min, // validation
      max, // validation
      nameRegex, // validation
    }
  },

  computed: {
    ...mapGetters({ colorPrimaryBtn: 'colorPrimaryBtn' }),

    countries() {
      const list = this.countriesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },

    states() {
      const list = this.statesList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchCountries()
    },

    'formPowerOfNotary.countryOfNotaryId': function (item, old) {
      if (item != null) {
        if (item != old && old != null && old !== '') {
          this.formPowerOfNotary.stateOfNotaryId = null
          this.statesList = []
        }
        this.fetchStates(item)
      } else {
        this.formPowerOfNotary.stateOfNotaryId = null
        this.statesList = []
      }
    },
  },

  mounted() {
    this.fetchCountries()
    this.assignDataFormPOA()
  },

  methods: {
    validatorFileSize,
    validatorFileType,
    ...mapActions(['fetchPowerInfo', 'savePower', 'updatePower']),

    fetchCountries() {
      countryService.getCountries().then(({ data }) => {
        this.countriesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchStates(id) {
      const filtersObj = { country_hash: id }

      countryService.getStates(filtersObj).then(({ data }) => {
        this.statesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignDataFormPOA() {
      this.fetchCountries()

      if (this.actions.name == 'edit') {
        this.applyOverlay = true
        powerOAService.getPowerInfo(this.actions.powerId).then(({ data }) => {
          const powerData = data.data
          this.formPowerOfNotary.id = powerData.hash
          this.formPowerOfNotary.clubId = powerData.club_hash
          this.formPowerOfNotary.instrumentDate = powerData.instrument_date
          this.formPowerOfNotary.instrumentNumber = powerData.instrument_number.instrument_number
          this.formPowerOfNotary.countryOfNotaryId = powerData.instrument_number.country_of_notary_hash
          this.formPowerOfNotary.stateOfNotaryId = powerData.instrument_number.state_of_notary_hash
          this.formPowerOfNotary.notaryName = powerData.instrument_number.notary_name
          this.formPowerOfNotary.notaryNumber = powerData.instrument_number.notary_number
          this.formPowerOfNotary.documentFile = powerData.document_media_file
          this.formPowerOfNotary.documentFileId = powerData.document_media_file.hash
          this.formPowerOfNotary.observations = powerData.observations
          this.formPowerOfNotary.status = powerData.status
          this.formPowerOfNotary.isCurrent = powerData.is_current
          this.applyOverlay = false
        }).catch(error => {
          this.applyOverlay = false
          this.responseCatch(error)
        })
      } else {
        this.formPowerOfNotary = {
          id: null,
          clubId: null,
          instrumentDate: null,
          instrumentNumber: null,
          countryOfNotaryId: '',
          stateOfNotaryId: '',
          notaryName: null,
          notaryNumber: null,
          documentFile: null,
          observations: null,
          status: true,
          isCurrent: true,
        }
      }
    },

    assignTextEdit(pText, successRule) {
      this.formPowerOfNotary.observations = pText
      this.formPowerOfNotary.documentFile = this.actions.name == 'edit' ? this.formPowerOfNotary.documentFile : this.auxFile

      this.$refs.pOARules.validate().then(success => {
        if (success && successRule) {
          if (this.actions.name == 'edit') {
            const formData = {
              id: this.actions.powerId,
              club_id: parseInt(this.actions.clubId, 10),
              instrument_date: this.formPowerOfNotary.instrumentDate,
              instrument_number: this.formPowerOfNotary.instrumentNumber,
              country_of_notary_id: this.formPowerOfNotary.countryOfNotaryId,
              state_of_notary_id: this.formPowerOfNotary.stateOfNotaryId,
              notary_name: this.formPowerOfNotary.notaryName,
              notary_number: this.formPowerOfNotary.notaryNumber,
              document_file: this.formPowerOfNotary.documentFile,
              document_file_id: this.formPowerOfNotary.documentFileId,
              observations: this.formPowerOfNotary.observations,
              status: this.formPowerOfNotary.status ? 1 : 0,
              is_current: this.formPowerOfNotary.isCurrent ? 1 : 0,
            }

            powerOAService.updatePower(formData).then(({ data }) => {
              this.responseSuccessUpdate(data.message)
              this.applyDeleteFile ? this.deleteFileX() : this.$emit('change-type-view')
            }).catch(error => {
              this.responseCatch(error)
            })
          } else {
            const formData = new FormData()
            formData.append('club_id', parseInt(this.actions.clubId, 10))
            formData.append('instrument_date', this.formPowerOfNotary.instrumentDate)
            formData.append('instrument_number', this.formPowerOfNotary.instrumentNumber)
            formData.append('country_of_notary_id', this.formPowerOfNotary.countryOfNotaryId)
            formData.append('state_of_notary_id', this.formPowerOfNotary.stateOfNotaryId)
            formData.append('notary_name', this.formPowerOfNotary.notaryName)
            formData.append('notary_number', this.formPowerOfNotary.notaryNumber)
            formData.append('document_file', this.formPowerOfNotary.documentFile)
            formData.append('observations', this.formPowerOfNotary.observations)
            formData.append('status', this.formPowerOfNotary.status ? 1 : 0)
            formData.append('is_current', this.formPowerOfNotary.isCurrent ? 1 : 0)

            powerOAService.storePower(formData).then(({ data }) => {
              this.responseSuccessCreate(data.message)
              this.$emit('change-type-view')
            }).catch(error => {
              this.responseCatch(error)
            })
          }
        } else {
          this.updatingDataPOA = false
        }
      })
    },

    assignFileDinamic(responseFile) {
      if (this.formPowerOfNotary.documentFileId == null) {
        this.formPowerOfNotary.documentFile = responseFile
        this.formPowerOfNotary.documentFileId = responseFile.hash
      } else {
        this.previousFileHash = this.formPowerOfNotary.documentFileId
        this.applyDeleteFile = true
        this.formPowerOfNotary.documentFile = responseFile
        this.formPowerOfNotary.documentFileId = responseFile.hash
      }
    },

    deleteFileX() {
      destroyMediaFile(this.previousFileHash).then(response => {
        this.$emit('change-type-view')
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fileValidation(event) {
      if (event.target.file !== 'undefined') {
        const fileX = event.target.files[0]
        // const validSize = this.validatorFileSize(fileX)
        const validType = this.validatorFileType(fileX)

        // if (!validSize) {
        //   this.$refs['file-x-input'].reset()
        //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
        // }

        if (!validType) {
          this.$refs['file-x-input'].reset()
          this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        }
      } else this.$refs['file-x-input'].reset()
    },
  },
}
</script>

<style scoped>
.swal-button--confirm {
  background-color: pink;
}
</style>
